import React, { useState } from 'react';
// import logo from './logo.svg';
import './App.css';
import { useAuth0 } from "@auth0/auth0-react";
import AppLoading from './AppLoading';
import AppRouter from './AppRouter';

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const { loginWithRedirect, isAuthenticated, isLoading, getIdTokenClaims, user, logout } = useAuth0();

  const getToken = async () => {
    try {
      const accessToken = await getIdTokenClaims()
      localStorage.setItem('token', accessToken.__raw)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
    }
  }
  React.useEffect(() => {
    if(!isLoading && !isAuthenticated) loginWithRedirect()

    if(isAuthenticated) {
      const roles: String[] = user && user['https://hasura.io/jwt/claims']['x-hasura-allowed-roles']
      const isAdmin = roles.includes('admin')
      if (!isAdmin) logout({ returnTo: window.location.origin })
      getToken()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return loading ? <AppLoading /> : <AppRouter />
}

export default App;
