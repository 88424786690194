import { Layout, Skeleton } from 'antd';
const AppLoading = () => {
  const { Header, Sider } = Layout;
  return (
    <Layout style={{height: '100vh'}}>
      <Header style={{padding: '12px 50px'}}>
        <Skeleton.Input style={{ width: 120 }} active />
      </Header>
      <Layout>
        <Sider width={200} style={{padding: 10}} className="site-layout-background">
          <Skeleton paragraph={true} active/>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Skeleton active/>
          <Skeleton active/>
          <Skeleton active/>
          <Skeleton active/>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default AppLoading
