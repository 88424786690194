import React, { createContext, FC, useCallback, useState } from 'react'

import { Cloudinary } from '@cloudinary/url-gen';

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
  },
  url: {
    secure: true // force https, set to false to force http
  }
});

type Props = {
  setState: React.Dispatch<React.SetStateAction<Props["state"]>>
  state: {},
  events: {
    getCloudinaryImageUrl: (public_id: string, type?: string) => string
  }
}

const initialValues: Props = {
  setState: () => {},
  state: {},
  events: {
    getCloudinaryImageUrl: () => ''
  }
}

const ImageContext = createContext<Props>(initialValues)

const useImageContext = () => {
  const [state, setState] = useState<Props['state']>(initialValues.state)
  const getCloudinaryImageUrl = useCallback(
    (public_id: string, type?: string) => {
      if (type && type === 'cloud') {
        const image = cld.image(public_id)
        return image.toURL()
      } else {
        const timestamp = Date.now().toString()
        const image = `https://res.cloudinary.com/bakaoo-indonesia/image/upload/v${timestamp}/${public_id}.png`
        return image
      }
    }, [])
  return {
    state,
    setState,
    events: {
      getCloudinaryImageUrl
    }
  }
}

const ImageProvider: FC = props => {
  const { Provider } = ImageContext
  return <Provider value={useImageContext()}>{props.children}</Provider>
}

export { ImageContext, ImageProvider }
