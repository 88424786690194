import React from "react";
import "antd/dist/antd.css";
import "./Layout.css";
import { Layout, Menu, Breadcrumb, Image } from "antd";
import {
  DashboardOutlined,
  HomeOutlined,
  SettingOutlined,
  FileTextOutlined,
  UnorderedListOutlined,
  UserOutlined,
  BarChartOutlined,
  TagOutlined,
  SettingFilled,
  ContainerOutlined,
  FileDoneOutlined,
  FileImageOutlined,
  QuestionCircleOutlined,
  ShoppingOutlined
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";

const { SubMenu } = Menu;
const { Header, Content, Sider, Footer } = Layout;

const LayoutContainer: React.FC = (props) => {
  const { logout } = useAuth0();
  const location = useLocation();
  const breadcrumbs = location.pathname.split("/");
  const history = useHistory();

  return (
    <Layout>
      <Header>
        <Image
          src={window.location.origin + "/logo.png"}
          className="logo"
          width={120}
          preview={false}
        />
        <Menu theme="dark" mode="horizontal" style={{ float: "right" }}>
          <SubMenu icon={<SettingOutlined />}>
            <Menu.Item
              key="setting:1"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Keluar
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
      <Layout>
        <Sider breakpoint="lg" collapsedWidth="0" width={200} className="site-layout-background">
          <Menu
            defaultSelectedKeys={[breadcrumbs[1]]}
            mode="inline"
            // theme="dark"
            // inlineCollapsed={this.state.collapsed}
          >
            <Menu.Item
              key="dashboard"
              icon={<DashboardOutlined />}
              onClick={() => history.push("/dashboard")}
            >
              Dashboard
            </Menu.Item>
            <Menu.Item
              key="pengguna"
              icon={<UserOutlined />}
              onClick={() => history.push("/users")}
            >
              Pengguna
            </Menu.Item>
            <Menu.Item
              key="toko"
              icon={<HomeOutlined />}
              onClick={() => history.push("/toko")}
            >
              Toko
            </Menu.Item>
            <Menu.Item
              key="blog"
              icon={<FileTextOutlined />}
              onClick={() => history.push("/blog")}
            >
              Blog
            </Menu.Item>
            <Menu.Item
              key="halaman"
              icon={<FileTextOutlined />}
              onClick={() => history.push("/halaman")}
            >
              Halaman
            </Menu.Item>
            <Menu.Item
              key="kategori-produk"
              icon={<UnorderedListOutlined />}
              onClick={() => history.push("/kategori-produk")}
            >
              Kategori Produk
            </Menu.Item>
            <Menu.Item
              key="kategori-blog"
              icon={<UnorderedListOutlined />}
              onClick={() => history.push("/kategori-blog")}
            >
              Kategori Blog
            </Menu.Item>
            <Menu.Item
              key="transaksi"
              icon={<BarChartOutlined />}
              onClick={() => history.push("/transaksi")}
            >
              Transaksi
            </Menu.Item>
            <Menu.Item
              key="produk-terjual"
              icon={<BarChartOutlined />}
              onClick={() => history.push("/produk-terjual")}
            >
              Produk terjual
            </Menu.Item>
            <Menu.Item
              key="promo"
              icon={<TagOutlined />}
              onClick={() => history.push("/promo")}
            >
              Promo
            </Menu.Item>
            <Menu.Item
              key="kontak"
              icon={<ContainerOutlined />}
              onClick={() => history.push("/kontak")}
            >
              Kontak
            </Menu.Item>
            <Menu.Item
              key="pengumuman"
              icon={<FileDoneOutlined />}
              onClick={() => history.push("/pengumuman")}
            >
              Pengumuman
            </Menu.Item>
            <Menu.Item
              key="pengumuman-promo"
              icon={<FileImageOutlined />}
              onClick={() => history.push("/pengumuman-promo")}
            >
              Pengumuman Promo
            </Menu.Item>
            <Menu.Item
              key="faq"
              icon={<QuestionCircleOutlined />}
              onClick={() => history.push("/faq")}
            >
              FAQ
            </Menu.Item>
            <Menu.Item
              key="kurir"
              icon={<ShoppingOutlined />}
              onClick={() => history.push("/kurir")}
            >
              Kurir
            </Menu.Item>
            <Menu.Item
              key="konfigurasi"
              icon={<SettingFilled />}
              onClick={() => history.push("/konfigurasi")}
            >
              Konfigurasi
            </Menu.Item>
            {/* <SubMenu key="sub1" icon={<UserOutlined />} title="subnav 1">
              <Menu.Item key="1">option1</Menu.Item>
              <Menu.Item key="2">option2</Menu.Item>
              <Menu.Item key="3">option3</Menu.Item>
              <Menu.Item key="4">option4</Menu.Item>
            </SubMenu>
            <SubMenu key="sub2" icon={<LaptopOutlined />} title="subnav 2">
              <Menu.Item key="5">option5</Menu.Item>
              <Menu.Item key="6">option6</Menu.Item>
              <Menu.Item key="7">option7</Menu.Item>
              <Menu.Item key="8">option8</Menu.Item>
            </SubMenu>
            <SubMenu key="sub3" icon={<NotificationOutlined />} title="subnav 3">
              <Menu.Item key="9">option9</Menu.Item>
              <Menu.Item key="10">option10</Menu.Item>
              <Menu.Item key="11">option11</Menu.Item>
              <Menu.Item key="12">option12</Menu.Item>
            </SubMenu> */}
          </Menu>
        </Sider>
        <Layout
          style={{ padding: "0 24px 24px", minHeight: "calc(100vh - 64px)" }}
        >
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Bakaoo</Breadcrumb.Item>
            {breadcrumbs.map((b: any, key: number) => {
              if (b) {
                return (
                  <Breadcrumb.Item key={key}>
                    {b.replace(/-/g, "_").charAt(0).toUpperCase() + b.slice(1)}
                  </Breadcrumb.Item>
                );
              }
              return null;
            })}
          </Breadcrumb>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Bakaoo ©2021 Created by Bakaoo
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutContainer;
