import { createClient } from 'urql';

const UrqlClient = createClient({
  url: process.env.REACT_APP_HASURA_URL || '',
  fetchOptions: () => {
    const token = localStorage.getItem('token')
    return {
      headers: { authorization: token ? `Bearer ${token}` : '' },
    };
  },
});

export default UrqlClient
