import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Layout from "../src/components/Layout";
import UrqlClient from "./lib/urqlClient";
import { Provider } from "urql";
import { Toaster } from 'react-hot-toast';
import { ImageProvider } from './contexts/ImageContext';

const Dashboard = lazy(() => import("../src/pages/dashboard/Dashnoard"));
const Tenants = lazy(() => import("../src/pages/tenants/Tenants"));
const Tenant = lazy(() => import("../src/pages/tenants/Tenant"));
const Blog = lazy(() => import("../src/pages/blog/Blog"));
const ProductCategories = lazy(() => import("../src/pages/productCategories/ProductCategories"));
const BlogCategories = lazy(() => import("../src/pages/blogCategories/BlogCategories"));
const Users = lazy(() => import("../src/pages/users/Users"));
const Promo = lazy(() => import("../src/pages/banners/Banners"));
const Trx = lazy(() => import("../src/pages/transactions/Transactions"));
const SingleTrx = lazy(() => import("../src/pages/transactions/Transaction"));
const Config = lazy(() => import("../src/pages/config/Config"));
const ProductDetail = lazy(() => import("../src/pages/tenants/products/ProductDetail"));
const Submissions = lazy(() => import("../src/pages/submissions/Submissions"));
const AnnouncementsBanner = lazy(() => import("../src/pages/annaouncementsBanner/AnnouncementsBanner"));
const AnnouncementsModal = lazy(() => import("../src/pages/annaouncementsModal/AnnouncementsModal"));
const Faqs = lazy(() => import("../src/pages/faqs/Faqs"));
const Pages = lazy(() => import("../src/pages/pages/Pages"));
const Couriers = lazy(() => import("../src/pages/couriers/Couriers"));
const Variants = lazy(() => import("../src/pages/variants/Variants"));

const AppRouter: React.FC = () => {
  const RouteLoading = () => {
    return (
      <div className="mt-5">
        <span className="block w-48 h-12 rounded skeleton"></span>

        <div className="mt-5 border rounded">
          <div className="p-5 border-b">
            <span className="block w-48 h-8 rounded skeleton"></span>
          </div>
          <div className="p-5">
            <div className="w-full h-screen skeleton"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Provider value={UrqlClient}>
      <ImageProvider>
        <Router>
          <Layout>
            <Switch>
              <Suspense fallback={<RouteLoading />}>
                <Route
                  exact={true}
                  path={"/"}
                  render={() => <Redirect to="/dashboard" />}
                />
                <Route exact={true} path={"/dashboard"} component={Dashboard} />
                <Route exact={true} path={"/toko"} component={Tenants} />
                <Route exact={true} path={"/toko/view/:id"} component={Tenant} />
                <Route exact={true} path={"/toko/produk/:id"} component={ProductDetail} />
                <Route exact={true} path={"/blog"} component={Blog} />
                <Route exact={true} path={"/kategori-produk"} component={ProductCategories} />
                <Route exact={true} path={"/kategori-blog"} component={BlogCategories} />
                <Route exact={true} path={"/users"} component={Users} />
                <Route exact={true} path={"/promo"} component={Promo} />
                <Route exact={true} path={"/transaksi"} component={Trx} />
                <Route exact={true} path={"/transaksi/view/:id"} component={SingleTrx} />
                <Route exact={true} path={"/konfigurasi"} component={Config} />
                <Route exact={true} path={"/kontak"} component={Submissions} />
                <Route exact={true} path={"/pengumuman"} component={AnnouncementsBanner} />
                <Route exact={true} path={"/pengumuman-promo"} component={AnnouncementsModal} />
                <Route exact={true} path={"/faq"} component={Faqs} />
                <Route exact={true} path={"/halaman"} component={Pages} />
                <Route exact={true} path={"/kurir"} component={Couriers} />
                <Route exact={true} path={"/produk-terjual"} component={Variants} />
              </Suspense>
            </Switch>
            <Toaster />
          </Layout>
        </Router>
      </ImageProvider>
    </Provider>
  );
};

export default AppRouter;
